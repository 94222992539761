var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-h3 mb-8"},[_vm._v(" Выбор сотрудников ")]),_c('div',[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"8"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.add_staff.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('div',{class:[_vm.$style.staffSwitch]},[_c('v-switch',{staticClass:"mt-1 ml-1",attrs:{"color":"accent darken-4","label":"Новый сотрудник"},on:{"change":function($event){_vm.new_staff === !_vm.new_staff}},model:{value:(_vm.new_staff),callback:function ($$v) {_vm.new_staff=$$v},expression:"new_staff"}})],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[(_vm.new_staff)?_c('TextField',{class:_vm.$style.item,attrs:{"label":"ФИО сотрудника","hide-details":"","require":"","dense":"","rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; } ]},model:{value:(_vm.staff.full_name),callback:function ($$v) {_vm.$set(_vm.staff, "full_name", $$v)},expression:"staff.full_name"}}):_c('Select',{class:[_vm.$style.select, _vm.$style.item],attrs:{"value":_vm.staff.full_name,"items":_vm.staffs,"label":"ФИО сотрудника","item-text":"full_name","return_object":"","hide-details":"","blue_icon":"","background-color":"#fff","require":"","dense":"","is_autocomplete":"","rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; } ]},on:{"input":function($event){return _vm.upd_staff($event)}}})],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('TextField',{class:_vm.$style.item,attrs:{"label":"E-mail","hide-details":"","require":"","dense":"","rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_email(v) || _vm.invalid_email; }
              ]},model:{value:(_vm.staff.email),callback:function ($$v) {_vm.$set(_vm.staff, "email", $$v)},expression:"staff.email"}}),_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],class:_vm.$style.item,attrs:{"label":"Телефон","hide-details":"","rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
              ],"require":"","dense":""},model:{value:(_vm.staff.phone),callback:function ($$v) {_vm.$set(_vm.staff, "phone", $$v)},expression:"staff.phone"}})],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('TextField',{class:_vm.$style.item,attrs:{"label":"Сайт","hide-details":"","dense":"","rules":[
                function (v) { return _vm.is_url(v) || _vm.invalid_url; }
              ]},model:{value:(_vm.staff.url),callback:function ($$v) {_vm.$set(_vm.staff, "url", $$v)},expression:"staff.url"}}),_c('FileInput',{class:_vm.$style.item,attrs:{"value":_vm.staff.image,"label":"Загрузите фото","hint":"Рекомендуемый формат 3х4","persistent-hint":"","require":"","dense":"","accept":".jpeg, .png, .jpg, .svg, .JPG, .JPEG, .PNG, .SVG"},on:{"change":function($event){_vm.staff.image = $event}}})],1),_c('div',{class:[_vm.$style.itemsRow, _vm.$style.itemEmployeeFiled, 'mb-6']},[_c('TextField',{class:_vm.$style.item,attrs:{"label":"Должность","hide-details":"","require":"","dense":"","rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; } ]},model:{value:(_vm.staff.job_title),callback:function ($$v) {_vm.$set(_vm.staff, "job_title", $$v)},expression:"staff.job_title"}})],1),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"accent darken-4","depressed":"","type":"submit","disabled":!_vm.is_valid}},[_vm._v(" Добавить сотрудника в КП ")])],1)],1)],1),_c('Table',{attrs:{"headers":_vm.headers,"items":_vm.new_offer.users,"no-data-text":"Сотрудники отсутствуют","not_resizeble":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":"","open-delay":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"accent darken-4","disabled":_vm.is_not_valid_open_photo(item)},on:{"click":function($event){$event.stopPropagation();return _vm.open_photo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотреть")])]),_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":"","open-delay":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"secondary darken-4"},on:{"click":function($event){return _vm.delete_staff(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить")])])]}}],null,true)}),_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-auto",attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")]),_c('div',[_c('v-btn',{staticClass:"mr-auto",attrs:{"depressed":""},on:{"click":_vm.go_back}},[_vm._v(" Назад ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"accent darken-4","depressed":"","disabled":_vm.new_offer.users.length < 1},on:{"click":_vm.save_offer}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }